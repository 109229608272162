$bkg10: rgba(28, 132, 250, 0.1);
$bkg30: rgba(28, 132, 250, 0.3);
$defaultShadow: 1px 1px 1px 2px $bkg30;

.next-steps {
  background-color: $bkg10;
  margin-bottom: 5rem;
  box-shadow: $defaultShadow;
  -webkit-box-shadow: $defaultShadow;
  -moz-box-shadow: $defaultShadow;
  .my-5 {
    padding-top: 2rem;
    padding-bottom: 0;
  }
  .subtitle {
    padding-bottom: 2rem;
  }
}
