body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 20vh auto;
  height: 20vh;
  width: 20vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.loading {
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
}

.userImg {
  border-radius: 100px;
  display: block;
  height: 100px;
  margin: 0 auto;
  width: 100px;
}

.hero .app-logo {
  max-width: 10.5rem;
}

.logo {
  background-image: url('https://raw.githubusercontent.com/triciopa/triciopa/f6971a7eba30bc17cb957d3b1577202a2debd4d7/logos/myface/foto3-pixel64.png');
  width: 3.5rem;
  height: 4rem;
}
