$bkg30: rgba(28, 132, 250, 0.3);

.hero {
  margin-top: 20px;
  max-width: 630px;

  p.lead {
    margin-bottom: 4rem;
  }

  .hero-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4rem;

    a {
      text-decoration: none;
      color: inherit;
    }

    .hero-card {
      // margin: 0 20px;
      box-shadow: 2px 2px 2px 2px $bkg30;
      height: fit-content;
      img {
        width: 250px;
        height: 200px;
      }
    }
  }
}
