.container {
  .btn-group {
    * {
      margin: 0 10px;
    }

    button {
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  ul {
    margin-top: 45px;
  }
}
